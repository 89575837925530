.CouponLocation{
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 30px 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
} 
.CouponLocation h3{
    margin: 0;
    color: #011B3C;
    /* text-align: center; */
    font-family: Cairo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.CouponLocation h3 span{
    color: #011B3C;
    font-family: Cairo;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
@media(max-width:767px){
    .CouponLocation{
        align-items: flex-start;
        gap: 8px;
        padding: 20px 10px;
    }
    .CouponLocation svg{
        margin-top: 5px;
    }
    .CouponLocation h3{
        font-size: 16px;
        font-weight: 700;
    }
    .CouponLocation h3 span{
        font-size: 15px;
        font-weight: 500;
    }
}