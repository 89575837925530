.CoupounTInfo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}
.CoupounTInfo .info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
}
.CoupounTInfo .info .info_line{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    border: 1px solid #000;
    padding: 10px;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 125%; /* 25px */
}
@media(max-width:767px){
    .CoupounTInfo .info .info_line{
        font-size: 14px;    
    }  
    .CoupounTInfo .info{
        margin-bottom: 40px;
        gap: 10px;
    }
}