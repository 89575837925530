.CouponFour_main{
    width: 100%;
    min-height: 100vh !important;
    background: #F9F9F9;
    display: flex;
    justify-content: flex-start;
    direction: rtl;
}
.CouponFour_main .CouponFour_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    /* direction: rtl; */
}