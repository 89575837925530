@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

* {
  --webkit-box-sising: border-box;
  --moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Inter', sans-serif !important;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  /* position: relative; */
}
