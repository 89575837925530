@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');
.couponFive_main{
    background: #efefef;
    width: 100%;
    min-height: 100vh !important;
    direction: rtl;
    justify-content: flex-start;
    padding: 15px;
    font-family: "Noto Sans Arabic", sans-serif !important;

}
.couponFive_main .container{
    padding: 15px;
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin-bottom: 25px;
}