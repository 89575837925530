.CopyPart{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.btnCopy{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}
.btnCopy input{
    width: 230px;
    height: 65px;
    border: none;
    outline: none;
    background: #F6F6F6;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 125%; /* 30px */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23011B3CFF' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
}
.btnCopy button{
    border: none;
    outline: none;
    background: #000;
    width: 230px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 25px */
    border-radius: 10px;
}
.btn_copy2 button{
    background: #000;
    border: 1px solid #000;

}
.shareBtnsVisit{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.shareBtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.shareBtn button, .shareBtn a{
    border: none;
    border-radius: 50px;
    background: #F2F2F2;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shareBtn span{
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 125%; /* 15px */
}
.shareCotainer{
    min-width: 158px;
    height: 51px;
    border-radius: 12px;
    border: 1px solid #777;
    background: #F2F2F2;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    display: none;
    transition: .6s ease;
}
.shareCotainer i{
    font-size: 32px;
    cursor: pointer;
}
.active_share{
    display: flex;
}
@media(max-width:767px){
    .btnCopy button{
        /* font-size: 16px; */
        /* width: 114px; */

    }
    .btnCopy input{
        /* width: 180px; */
    }
}